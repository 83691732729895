/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, {useRef, useState} from 'react';
import { graphql, Link } from 'gatsby';
import {GoogleApiWrapper} from "google-maps-react";
import classNames from "classnames";
import nl2br from 'nl2br';
import { useSwipeable } from 'react-swipeable';
import { v5 as uuidv5 } from 'uuid';
import SEO from "../components/seo";
import Layout from "../components/full-page/layout";
// import '../styles/react.css';
import '../styles/search-input.scss';
import Map from '../components/map';
import {mobileAndTabletCheck} from "../utils/detect";
import CCLogoWhite from "../images/Claritycoat AF new.svg";
import { ColorThemeContextProvider } from "../contexts/colorThemeContext";
import * as styles from '../components/full-page/header/header.module.scss';
import authorisedImg from '../images/authorized.svg';

const FindAnInstaller = ({ location, data, google, pageContext: { lang } }) => {
    const document = data.prismicLocations;
    const locations = document.data.location;
    const LOCATIONS_NAMESPACE = '1b671a64-40d5-491e-99b0-da01ff1f3341';
    locations.map(location => location.id = uuidv5(location.company_name.text + location.map_location?.latitude + location.map_location?.longitude, LOCATIONS_NAMESPACE));

    const meta = [
        {
            name: `description`,
            content: document.data.meta_description.text,
        },
        {
            property: `og:title`,
            content: document.data.meta_title.text,
        },
        {
            property: `og:description`,
            content: document.data.meta_description.text,
        },
        {
            name: `twitter:description`,
            content: document.data.meta_description.text,
        },
        {
            name: `og:image`,
            content: document.data.share_image.url,
        },
    ];

    const [showInstallers,  setShowInstallers] = useState(true);
    const [showDistributors, setShowDistributors] = useState(true);
    const [showFilters, setShowFilters] = useState(false);

    const centres = [];
    if (showInstallers) {
        const installers = locations.filter(location => location.type === 'Installer');
        centres.push(...installers);
    }

    if (showDistributors) {
        const distributors = locations.filter(location => location.type === 'Distributor');
        centres.push(...distributors);
    }

    const [selectedCentre, selectCentre] = useState({});

    /**
     * On change address input
     **/
    const [currentPlace, setCurrentPlace] = useState('');
    const [placePredictions, setPlacePredictions] = useState([]);
    const [selectedPrediction, setSelectedPrediction] = useState({});
    const [autocompleteDropdownVisibility, setAutocompleteDropdownVisibility] = useState(false);
    const [mapLocation, setMapLocation] = useState({});
    const displaySuggestions = (predictions, status) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
            return;
        }

        setPlacePredictions(predictions);

        if (predictions.length > 0) {
            setSelectedPrediction(predictions[0]);
        }
    }

    const geocode = (placeId) => {
        const service = new google.maps.Geocoder();

        service.geocode({placeId: placeId}, (results, status) => {
            if (status !== google.maps.GeocoderStatus.OK ) {
                return;
            }

            setMapLocation(results[0]);
        });
    }

    const onChangeAddressInput = e => {
        setCurrentPlace(e.target.value);
        const service = new google.maps.places.AutocompleteService();
        if (e.target.value) {
            service.getQueryPredictions({ input: e.target.value }, displaySuggestions);
        } else {
            setSelectedPrediction({});
            setPlacePredictions([]);
        }
    }

    const onFocusAddressInput = e => {
        setAutocompleteDropdownVisibility(true)
    }

    const onBlurAddressInput = e => {
        setTimeout(() => setAutocompleteDropdownVisibility(false), 200);
    }

    const onAddressInputKeyUp = e => {
        if ((e.key === 'Enter' || e.keyCode === 13) && selectedPrediction) {
            setCurrentPlace(`${selectedPrediction.structured_formatting?.main_text}${selectedPrediction.structured_formatting?.secondary_text ? ', ' + selectedPrediction.structured_formatting?.secondary_text : ''}`);
            setPlacePredictions([selectedPrediction,]);
            geocode(selectedPrediction.place_id);
            e.target.blur();
        }

        // Up Key
        if (e.keyCode === 38 && placePredictions.length > 1 && selectedPrediction) {
            const selPredictionIndex = placePredictions.findIndex(prediction => prediction.place_id === selectedPrediction.place_id);

            if (selPredictionIndex === 0) {
                setSelectedPrediction(placePredictions[placePredictions.length - 1]);
            } else {
                setSelectedPrediction(placePredictions[selPredictionIndex - 1]);
            }
        }

        // down key
        if (e.keyCode === 40 && placePredictions.length > 1 && selectedPrediction) {
            const selPredictionIndex = placePredictions.findIndex(prediction => prediction.place_id === selectedPrediction.place_id);

            if (selPredictionIndex === placePredictions.length - 1) {
                setSelectedPrediction(placePredictions[0]);
            } else {
                setSelectedPrediction(placePredictions[selPredictionIndex+1]);
            }
        }
    }

    const onClickButtonClear = e => {
        setCurrentPlace('');
        setPlacePredictions([]);
        setSelectedPrediction({});
    }

    const onClickPrediction = prediction => {
        setSelectedPrediction(prediction);
        setPlacePredictions([prediction,]);
        setCurrentPlace(`${prediction.structured_formatting.main_text}${prediction.structured_formatting.secondary_text ? ', ' + prediction.structured_formatting.secondary_text : ''}`);
        geocode(prediction.place_id);
    }

    /**
     * Apply filters on mobile
     **/
    const [showInstallersBuffer,  setShowInstallersBuffer] = useState(true);
    const [showDistributorsBuffer, setShowDistributorsBuffer] = useState(true);
    const handleOpenFilterPanel = () => {
        setShowFilters(true);

        setShowInstallersBuffer(showInstallers);
        setShowDistributorsBuffer(showDistributors);
    }

    const handleApplyFilterPanel = () => {
        setShowFilters(false);

        setShowInstallers(showInstallersBuffer);
        setShowDistributors(showDistributorsBuffer);
    }

    const [isCardMoving, setIsCardMoving] = useState(false);
    const [isCardExpanded, setIsCardExpanded] = useState(false);
    const [cardTopPosition, setCardTopPosition] = useState(null);
    const [cardInitialPosition, setCardInitialPosition] = useState(null);
    const [scrollDim, setScrollDim] = useState(null);
    const cardMobileElem = useRef();
    const config = {
        preventDefaultTouchmoveEvent: false,
        trackTouch: true
    }
    const swipeHandlers = useSwipeable({
        onSwipedUp: (eventData) => {
            if (Math.abs(eventData.deltaY) > 50) {
                if (!isCardExpanded) {
                    setIsCardExpanded(true);
                }
            }

            setIsCardMoving(false);
            setCardTopPosition(null);
            setCardInitialPosition(null);
            setScrollDim(0);
        },
        onSwipedDown: (eventData) => {
            if (Math.abs(eventData.deltaY) > 50) {
                if (!isCardExpanded) {
                    selectCentre({});
                }

                setIsCardExpanded(false);
            }

            setIsCardMoving(false);
            setCardTopPosition(null);
            setCardInitialPosition(null);
            setScrollDim(0);
        },
        onSwiping: (eventData) => {
            if (!cardMobileElem.current) {
                return;
            }

            const cardPosition = cardInitialPosition || cardMobileElem.current.offsetTop;

            if (eventData.first) {
                setCardInitialPosition(cardMobileElem.current.offsetTop);
            }

            // const cardBody = eventData.event.path.filter(elem => elem.className === 'card-body');
            // if (cardBody.length > 0 && cardBody[0].scrollTop > 0 && eventData.dir === 'Down') {
            //     setScrollDim(eventData.deltaY);
            //     return;
            // }

            if (cardPosition && cardPosition + eventData.deltaY <= 65 && eventData.dir === 'Up') {
                setCardTopPosition(65);
                return;
            }

            if (cardMobileElem.current.offsetHeight <= 120 && eventData.dir === 'Down') {
                setCardTopPosition(window.innerHeight - 115);
                return;
            }

            setIsCardMoving(true);
            setCardTopPosition(cardPosition + eventData.deltaY - scrollDim);
        },
        ...config,
    });

    const refPassthrough = (el) => {
        // call useSwipeable ref prop with el
        swipeHandlers.ref(el);

        // set myRef el so you can access it yourself
        cardMobileElem.current = el;
    }

    const insta_rx = /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_\.]+)/im
    const fb_rx = /(?:(?:http|https):\/\/)?(?:www\.)?(?:facebook\.com)\/([A-Za-z0-9-_\.]+)/im


    return (
      <>
          <SEO title={document.data.title.text} meta={meta} canonical_link={document.data.canonical_link?.url} />
          <ColorThemeContextProvider>
              <Layout
                logo={CCLogoWhite}
                disableFooter={true}
                headerClass={styles.whiteBg}
                topNav={data.prismicHome?.data.menu_links}
                footerNav={data.prismicHome?.data.footer_links}
                blogListing={data.allPrismicBlogPost?.nodes}
                currentLanguage={lang}
              >
                  <section className="locate-a-centre-content ">
                      <div className="locate-a-centre-content__map">
                          <Map
                            google={ google }
                            centres={ centres }
                            onCentreMarkerClick={selectCentre}
                            selectedCentre={selectedCentre}
                            selectedAddress={mapLocation}
                            lang={lang}
                          />
                      </div>
                  </section>

                  <div className="filter_container">
                      <section className="filter filter--desktop">
                          <h3 className="filter-label">Filter By</h3>
                          <ul className="filter-items">
                              <li className="filter-item">
                                  <button className={classNames("filter-button", {"filter-button--selected": showInstallers})} onClick={() => setShowInstallers(!showInstallers)}>
                                      <i className="filter-icon icon-letter-i" /><span className="filter-name">Installers</span>
                                  </button>
                              </li>
                              <li className="filter-item">
                                  <button className={classNames("filter-button", {"filter-button--selected": showDistributors})} onClick={() => setShowDistributors(!showDistributors)}>
                                      <i className="filter-icon icon-letter-d" /><span className="filter-name">Distributors</span>
                                  </button>
                              </li>
                          </ul>
                      </section>
                      <section className={classNames("filter filter--mobile", {"filter--full_screen": showFilters})}>
                          <div role="presentation" className="filter-overlay" onClick={() => setShowFilters(false)} />
                          <div className="filter-drawer">
                              <ul className="filter-items">
                                  <li className="filter-item">
                                      <button className={classNames("filter-button", {"filter-button--selected": showInstallersBuffer})} onClick={() => setShowInstallersBuffer(!showInstallersBuffer)}>
                                          <i className="filter-icon icon-letter-i" /><span className="filter-name">Installers</span>
                                      </button>
                                  </li>
                                  <li className="filter-item">
                                      <button className={classNames("filter-button", {"filter-button--selected": showDistributorsBuffer})} onClick={() => setShowDistributorsBuffer(!showDistributorsBuffer)}>
                                          <i className="filter-icon icon-letter-d" /><span className="filter-name">Distributors</span>
                                      </button>
                                  </li>
                              </ul>
                              <div className="btn_group">
                                  <button className="btn btn--icon" aria-label="button cancel" onClick={() => setShowFilters(false)}><i className="icon icon--cancel" />Cancel</button>
                                  <button className="btn btn--icon" disabled="" aria-label="button apply" onClick={handleApplyFilterPanel}><i className="icon icon--apply" />Apply</button>
                              </div>
                          </div>
                      </section>
                  </div>

                  <div className={classNames("findus-search-input findus-search-input--lowered", {"findus-search-dropdown--shown": autocompleteDropdownVisibility, "findus-search-input--hidden": selectedCentre.id})}>
                      {!mobileAndTabletCheck() ? (
                        <button type="button" className="reverse-btn" aria-label="reverse button" onClick={onClickButtonClear}>
                            <i className="icon icon--cancel" />
                        </button>
                      ) : (
                        <button type="button" className="reverse-btn" aria-label="reverse button" onClick={handleOpenFilterPanel}>
                            <i className="icon icon--filter" />
                        </button>
                      )}
                      <input id="pac-input" name="" className="search-input tsla-icon-search" type="text" placeholder="Enter location" autoComplete="off" aria-label="search input" onChange={onChangeAddressInput} onFocus={onFocusAddressInput} onBlur={onBlurAddressInput} value={currentPlace} onKeyUp={onAddressInputKeyUp} />
                      {placePredictions.length > 0 && autocompleteDropdownVisibility &&
                        <ul id="autocomplete" className="autocomplete--dropdown">
                            {placePredictions.map(prediction => (
                              <li className={prediction.place_id === selectedPrediction.place_id ? "autocomplete--selected" : ""}
                                  key={`prediction-${prediction.place_id}`}
                                  onClick={(e) => onClickPrediction(prediction)}
                              >
                                  <i className="dropdown-item--icon tsla-icon-pin"/>{`${prediction.structured_formatting?.main_text}${prediction.structured_formatting?.secondary_text ? ', ' + prediction.structured_formatting?.secondary_text : ''}`}
                              </li>
                            ))}
                        </ul>
                      }
                  </div>

                  <div className="card_container">
                      <div className={classNames("card card--mobile", {"card--hidden": !selectedCentre.id, "card--expanded": true, "card--moving": isCardMoving})} role="presentation" style={isCardMoving ? { top: `${ cardTopPosition }px`, transition: "none 0s ease 0s" } : {}}>
                          {selectedCentre.id &&
                            <>
                                <div className="card-header">
                                    <div className="card-type">
                                        <span className={classNames("card-type_icon", {"icon-letter-d": selectedCentre.item.type === 'Distributor', "icon-letter-i": selectedCentre.item.type === 'Installer'})} />
                                        <h4 className="card-type_header">{selectedCentre.item.authorized && 'Authorised'} {selectedCentre.item.type} {selectedCentre.item.authorized && <img width={24} height={24} style={{verticalAlign: "middle"}} src={authorisedImg} alt="authorised centre" />}</h4>
                                    </div>
                                    <button className="card-close" onClick={() => { selectCentre({}); setCardTopPosition(null); setIsCardExpanded(false);}}>
                                        <img className="card-close_icon" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeBAMAAADJHrORAAAALVBMVEUAAABOTk5MTExNTU1MTExMTExPT09OTk5NTU1VVVVMTExNTU1NTU1OTk5MTEyacgmMAAAADnRSTlMAJOSbdfdHDcYDp5GILdILcTwAAACjSURBVBjTXc+xCQJhEEThEeGMBFGMjGzAzPSwCsPjEnuwCrENM0sQe7AB0cRganB1eSu4wcHxveAfaTQRN/589ktx6z74cCcYehtsE7R+9praESTbGw1WBK39kDIoFgFMABMkVzBLrqBLrgAmSObm8X/R71rzSF7OCpgAvmZQw1jBMGYyjJkwM2ECuILblwnOarpgZr6O0i6Y4CSpgaVF8N+9ATwGg8Fp9q1hAAAAAElFTkSuQmCC" alt="Close" />
                                    </button>
                                </div>
                                <div className="card-body">
                                    {!selectedCentre.item.logo.url ? (
                                      <h2 className="card-item card-item--name">{selectedCentre.item.company_name.text}</h2>
                                    ):(
                                      <div className="card-item card-item--logo"><img src={selectedCentre.item.logo.url} alt={selectedCentre.item.company_name.text}/></div>
                                    )}

                                    {selectedCentre.item.address.html &&
                                      <div className="card-item card-item--address">
                                          <div className="card-item_content" dangerouslySetInnerHTML={{__html: selectedCentre.item.address.html}}/>
                                      </div>
                                    }
                                    <div className="card-item card-item--directions">
                                        <p className="card-item_content">
                                            <a className="tsla-link tsla-link--primary" rel="noopener noreferrer" target="_blank" href={`https://www.google.com/maps/dir/?api=1&origin=&destination=${ selectedCentre.item.map_location.latitude }+${ selectedCentre.item.map_location.longitude }&travelmode=driving`}>Driving Directions</a>
                                        </p>
                                    </div>

                                    {selectedCentre.item.website.url &&
                                      <div className="card-item card-item--website">
                                          <p className="card-item_content">
                                              <a className="tsla-link tsla-link--primary" rel="noopener noreferrer" target="_blank" href={selectedCentre.item.website.url}>{selectedCentre.item.website.url} →</a>
                                          </p>
                                      </div>
                                    }

                                    {selectedCentre.item.telephone &&
                                      <div className="card-item">
                                          <p className="card-item_content">
                                              <strong>Phone</strong><br/>
                                              <a href={`tel:${ selectedCentre.item.telephone }`}>{selectedCentre.item.telephone}</a>
                                          </p>
                                      </div>
                                    }


                                    {selectedCentre.item.email &&
                                      <div className="card-item">
                                          <p className="card-item_content">
                                              <strong>E-mail</strong><br/>
                                              <a href={`mailto:${ selectedCentre.item.email }`}>{selectedCentre.item.email}</a>
                                          </p>
                                      </div>
                                    }

                                    {selectedCentre.item.facebook && fb_rx.exec(selectedCentre.item.facebook) &&
                                      <div className="card-item">
                                          <p className="card-item_content">
                                              <strong>Facebook</strong><br/>
                                              <a className="tsla-link tsla-link--primary" rel="noopener noreferrer" target="_blank" href={`${ selectedCentre.item.facebook }`}>@{fb_rx.exec(selectedCentre.item.facebook)[1]} →</a>
                                          </p>
                                      </div>
                                    }

                                    {selectedCentre.item.instagram && insta_rx.exec(selectedCentre.item.instagram) &&
                                      <div className="card-item">
                                          <p className="card-item_content">
                                              <strong>Instagram</strong><br/>
                                              <a className="tsla-link tsla-link--primary" rel="noopener noreferrer" target="_blank" href={`${ selectedCentre.item.instagram }`}>@{insta_rx.exec(selectedCentre.item.instagram)[1]} →</a>
                                          </p>
                                      </div>
                                    }

                                    {selectedCentre.item.operation_hours.html &&
                                      <div className="card-item">
                                          <p className="card-item_content">
                                              <strong>Sales Hours</strong><br/>
                                              <span dangerouslySetInnerHTML={{__html: selectedCentre.item.operation_hours.html}} />
                                          </p>
                                      </div>
                                    }

                                    {selectedCentre.item.services.html &&
                                      <div className="card-item">
                                          <p className="card-item_content">
                                              <strong>Services</strong><br/>
                                              <span dangerouslySetInnerHTML={{__html: selectedCentre.item.services.html}} />
                                          </p>
                                      </div>
                                    }

                                    {selectedCentre.item.amenities.html &&
                                      <div className="card-item">
                                          <p className="card-item_content">
                                              <strong>Amenities</strong><br/>
                                              <span dangerouslySetInnerHTML={{__html: selectedCentre.item.amenities.html}} />
                                          </p>
                                      </div>
                                    }

                                    {selectedCentre.item.about_us.html &&
                                      <div className="card-item">
                                          <p className="card-item_content">
                                              <strong>About us</strong><br/>
                                              <span dangerouslySetInnerHTML={{__html: selectedCentre.item.about_us.html}} />
                                          </p>
                                      </div>
                                    }

                                    {selectedCentre.item.brands.html &&
                                      <div className="card-item">
                                          <p className="card-item_content">
                                              <strong>Brands</strong><br/>
                                              <span dangerouslySetInnerHTML={{__html: selectedCentre.item.brands.html}} />
                                          </p>
                                      </div>
                                    }

                                    {selectedCentre.item.wifi &&
                                      <div className="card-item">
                                          <p className="card-item_content">
                                              <strong>Wifi</strong><br/>
                                              <span>{selectedCentre.item.wifi}</span>
                                          </p>
                                      </div>
                                    }
                                </div>
                                <div className="card-footer">
                                    <Link className="tsla-btn tsla-btn--blue" to={`/contact/`}>Request a Call</Link>
                                </div>
                            </>
                          }
                      </div>
                      <div className={classNames("card card--desktop", {"card--hidden": !selectedCentre.id})}>
                          {selectedCentre.id &&
                            <>
                                <div className="card-header">
                                    <div className="card-type">
                                        <span className={classNames("card-type_icon", {"icon-letter-d": selectedCentre.item.type === 'Distributor', "icon-letter-i": selectedCentre.item.type === 'Installer'})} />
                                        <h4 className="card-type_header">{selectedCentre.item.authorized && 'Authorised'} {selectedCentre.item.type} {selectedCentre.item.authorized && <img width={24} height={24} style={{verticalAlign: "middle"}} src={authorisedImg} alt="authorised centre" />}</h4>
                                    </div>
                                    <button className="card-close" onClick={() => selectCentre({})}>
                                        <img className="card-close_icon" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeBAMAAADJHrORAAAALVBMVEUAAABOTk5MTExNTU1MTExMTExPT09OTk5NTU1VVVVMTExNTU1NTU1OTk5MTEyacgmMAAAADnRSTlMAJOSbdfdHDcYDp5GILdILcTwAAACjSURBVBjTXc+xCQJhEEThEeGMBFGMjGzAzPSwCsPjEnuwCrENM0sQe7AB0cRganB1eSu4wcHxveAfaTQRN/589ktx6z74cCcYehtsE7R+9praESTbGw1WBK39kDIoFgFMABMkVzBLrqBLrgAmSObm8X/R71rzSF7OCpgAvmZQw1jBMGYyjJkwM2ECuILblwnOarpgZr6O0i6Y4CSpgaVF8N+9ATwGg8Fp9q1hAAAAAElFTkSuQmCC" alt="Close" />
                                    </button>
                                </div>
                                <div className="card-body">
                                    {!selectedCentre.item.logo.url ? (
                                      <h2 className="card-item card-item--name">{selectedCentre.item.company_name.text}</h2>
                                    ):(
                                      <div className="card-item card-item--logo"><img src={selectedCentre.item.logo.url} alt={selectedCentre.item.company_name.text}/></div>
                                    )}

                                    {selectedCentre.item.address.html &&
                                      <div className="card-item card-item--address">
                                          <div className="card-item_content" dangerouslySetInnerHTML={{__html: selectedCentre.item.address.html}}/>
                                      </div>
                                    }
                                    <div className="card-item card-item--directions">
                                        <p className="card-item_content">
                                            <a className="tsla-link tsla-link--primary" rel="noopener noreferrer" target="_blank" href={`https://www.google.com/maps/dir/?api=1&origin=&destination=${ selectedCentre.item.map_location.latitude }+${ selectedCentre.item.map_location.longitude }&travelmode=driving`}>Driving Directions</a>
                                        </p>
                                    </div>

                                    {selectedCentre.item.website.url &&
                                      <div className="card-item card-item--website">
                                          <p className="card-item_content">
                                              <a className="tsla-link tsla-link--primary" rel="noopener noreferrer" target="_blank" href={selectedCentre.item.website.url}>{selectedCentre.item.website.url} →</a>
                                          </p>
                                      </div>
                                    }

                                    {selectedCentre.item.telephone &&
                                      <div className="card-item">
                                          <p className="card-item_content">
                                              <strong>Phone</strong><br/>
                                              <a href={`tel:${ selectedCentre.item.telephone }`}>{selectedCentre.item.telephone}</a>
                                          </p>
                                      </div>
                                    }


                                    {selectedCentre.item.email &&
                                      <div className="card-item">
                                          <p className="card-item_content">
                                              <strong>E-mail</strong><br/>
                                              <a href={`mailto:${ selectedCentre.item.email }`}>{selectedCentre.item.email}</a>
                                          </p>
                                      </div>
                                    }

                                    {selectedCentre.item.facebook && fb_rx.exec(selectedCentre.item.facebook) &&
                                      <div className="card-item">
                                          <p className="card-item_content">
                                              <strong>Facebook</strong><br/>
                                              <a className="tsla-link tsla-link--primary" rel="noopener noreferrer" target="_blank" href={`${ selectedCentre.item.facebook }`}>@{fb_rx.exec(selectedCentre.item.facebook)[1]} →</a>
                                          </p>
                                      </div>
                                    }

                                    {selectedCentre.item.instagram && insta_rx.exec(selectedCentre.item.instagram) &&
                                      <div className="card-item">
                                          <p className="card-item_content">
                                              <strong>Instagram</strong><br/>
                                              <a className="tsla-link tsla-link--primary" rel="noopener noreferrer" target="_blank" href={`${ selectedCentre.item.instagram }`}>@{insta_rx.exec(selectedCentre.item.instagram)[1]} →</a>
                                          </p>
                                      </div>
                                    }

                                    {selectedCentre.item.operation_hours.html &&
                                      <div className="card-item">
                                          <p className="card-item_content">
                                              <strong>Sales Hours</strong><br/>
                                              <span dangerouslySetInnerHTML={{__html: selectedCentre.item.operation_hours.html}} />
                                          </p>
                                      </div>
                                    }

                                    {selectedCentre.item.services.html &&
                                      <div className="card-item">
                                          <p className="card-item_content">
                                              <strong>Services</strong><br/>
                                              <span dangerouslySetInnerHTML={{__html: selectedCentre.item.services.html}} />
                                          </p>
                                      </div>
                                    }

                                    {selectedCentre.item.amenities.html &&
                                      <div className="card-item">
                                          <p className="card-item_content">
                                              <strong>Amenities</strong><br/>
                                              <span dangerouslySetInnerHTML={{__html: selectedCentre.item.amenities.html}} />
                                          </p>
                                      </div>
                                    }

                                    {selectedCentre.item.about_us.html &&
                                      <div className="card-item">
                                          <p className="card-item_content">
                                              <strong>About us</strong><br/>
                                              <span dangerouslySetInnerHTML={{__html: selectedCentre.item.about_us.html}} />
                                          </p>
                                      </div>
                                    }

                                    {selectedCentre.item.brands.html &&
                                      <div className="card-item">
                                          <p className="card-item_content">
                                              <strong>Brands</strong><br/>
                                              <span dangerouslySetInnerHTML={{__html: selectedCentre.item.brands.html}} />
                                          </p>
                                      </div>
                                    }

                                    {selectedCentre.item.wifi &&
                                      <div className="card-item">
                                          <p className="card-item_content">
                                              <strong>Wifi</strong><br/>
                                              <span>{selectedCentre.item.wifi}</span>
                                          </p>
                                      </div>
                                    }
                                </div>
                                <div className="card-footer">
                                    <Link className="tsla-btn tsla-btn--blue" to={`/contact`}>Request a Call</Link>
                                </div>
                            </>
                          }
                      </div>
                  </div>
              </Layout>
          </ColorThemeContextProvider>
      </>
)};

export const centreLocations = graphql`
query CentreLocationsQuery($lang: String) {
  prismicLocations(lang: {eq: "en-gb"}) {
    id
    lang
    type
    url
    data {
      title {
        text
      }
      meta_title {
        text
      }
      share_image {
        url
      }
      meta_description {
        text
      }
      canonical_link {
        url
      }
      location {
        company_name {
          text
        }
        logo {
          alt
          url
        }
        type
        authorized
        about_us {
          html
        }
        address {
          html
        }
        amenities {
          html
        }
        brands {
          html
        }
        email
        facebook
        instagram
        operation_hours {
          html
        }
        map_location {
          latitude
          longitude
        }
        telephone
        services {
          html
        }
        wifi
        website {
          url
        }
      }
    }
  }
  
  prismicHome(lang: {eq: $lang}) {
    data {
      menu_links {
          menu_link {
              url
              type
          }
          name {
            text
          }
      }
      footer_links {
        footer_link {
         url
         type
        }
        name {
          text
        }
      }
    }
  }
  
  allPrismicBlogPost(filter: {lang: {eq: $lang}}) {
    nodes {
      url
      data {
        title {
            text
        }
      }
    }
  }
}
`;

export default GoogleApiWrapper({
    apiKey: "AIzaSyDlJdrXkepmqHG3yM-Me1kj5IG8OZ1hMZE",
})(FindAnInstaller);