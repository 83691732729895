import React, {useEffect, useState} from 'react';
import { Marker } from 'google-maps-react';
import PropTypes from 'prop-types';
import CurrentLocation from "./current-location";
import MarkerIcon from '../images/map-marker.png';
import { locationsDefault, defaultLanguage } from '../../prismic-configuration';

const CentresLocation = ({centres, onCentreMarkerClick, selectedCentre, lang, ...props}) => {
    const handleMarkerClick = (id, centre) => {
        onCentreMarkerClick({id: id, item: centre});
    }

    const locDefaults= lang in locationsDefault
      ? locationsDefault[lang]
      : locationsDefault[defaultLanguage];

    const [ centre, setCentre ] = useState(null);
    useEffect(() => {
        if (selectedCentre.id) {
            setCentre({
                lat: selectedCentre.item.map_location.latitude,
                lng: selectedCentre.item.map_location.longitude
            })
        }
    }, [selectedCentre]);

    return (
        <CurrentLocation
            centerAroundCurrentLocation={false}
            google={props.google}
            selectedLocation={centre}
            selectedAddress={props.selectedAddress}
            {...locDefaults}
        >
            {centres.map((centre) => (
                <Marker
                    key={centre.id}
                    title={centre.company_name?.text}
                    position={{lat: centre.map_location?.latitude, lng: centre.map_location?.longitude}}
                    onClick={() => handleMarkerClick(centre.id, centre)}
                    icon={{
                        url: MarkerIcon,
                        anchor: new props.google.maps.Point(19, 19),
                        scaledSize: new props.google.maps.Size(31, 31)
                    }}
                />
            ))}
        </CurrentLocation>
    )
}

CentresLocation.propTypes = {
    centres: PropTypes.array,
    selectedCentre: PropTypes.object,
    onCentreMarkerClick: PropTypes.func,
    lang: PropTypes.string.isRequired,
}

CentresLocation.defaultProps = {
    centres: [],
    onCentreMarkerClick: () => {},
    selectedCentre: {},
}

export default CentresLocation;