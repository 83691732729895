import React, {useState, useEffect, useRef, useCallback} from 'react';
import ReactDOM from 'react-dom';


const CurrentLocation = ({ initialCenter: { lat, lng }, selectedLocation, selectedAddress, google, centerAroundCurrentLocation, zoom, children, ...props}) => {
    const [ currentLocation, setCurrentLocation ] = useState({ lat: lat, lng: lng });
    const [ isMapLoaded, setIsMapLoaded ] = useState(false);
    const map = useRef();
    const mapElem = useRef();

    const mapStyles = {
        map: {
            position: 'absolute',
            width: '100%',
            height: '100%'
        }
    };

    const loadMap = useCallback(() => {
        if (google) {
            const maps = google.maps;

            // reference to the actual DOM element
            const node = ReactDOM.findDOMNode(mapElem.current);

            const { lat, lng } = currentLocation;
            const center = new maps.LatLng(lat, lng);

            const mapConfig = Object.assign(
                {},
                {
                    center: center,
                    zoom: zoom,
                    streetViewControl: false,
                    mapTypeControl: false,
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.RIGHT_CENTER,
                    },
                    fullscreenControl: false,
                }
            );

            // maps.Map() is constructor that instantiates the map
            map.current = new maps.Map(node, mapConfig);
            setIsMapLoaded(true);
        }

    }, [google, currentLocation, zoom]);

    /**
     * Load Map
     */
    useEffect(() => {
        loadMap();
    }, [google, loadMap]);

    /**
     * Recenter Map
     */
    useEffect(() => {
        const maps = google.maps;

        if (map.current) {
            let center = new maps.LatLng(currentLocation.lat, currentLocation.lng);
            map.current.panTo(center);
        }
    }, [currentLocation, google.maps]);

    /**
     * Centre Around Selected Installer/Distributor
     */
    useEffect(() => {
        const maps = google.maps;

        if (map.current && selectedLocation) {
            let center = new maps.LatLng(selectedLocation.lat, selectedLocation.lng);
            map.current.panTo(center);
        }
    }, [selectedLocation, google.maps]);

    /**
     * Center around current location
     */
    useEffect(() => {
        if (centerAroundCurrentLocation) {
            if (navigator && navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(pos => {
                    const coords = pos.coords;
                    setCurrentLocation({
                        lat: coords.latitude,
                        lng: coords.longitude,
                    });
                });
            }

            loadMap();
        }
    }, [centerAroundCurrentLocation, loadMap]);

    /**
     * Recenter map around selected address
     */
    useEffect(() => {
        if (map.current && selectedAddress && selectedAddress.geometry && selectedAddress.geometry.viewport) {
            map.current.fitBounds(selectedAddress.geometry.viewport);
        }
    }, [selectedAddress]);

    const renderChildren = () => {
        if (!children) return;

        return React.Children.map(children, c => {
            if (!c) return;

            return React.cloneElement(c, {
                map: map.current,
                google: google,
                mapCenter: currentLocation
            });
        });
    }


    return (
        <div>
            <div style={mapStyles.map} ref={mapElem}>
                {renderChildren()}
            </div>

        </div>
    );
}

CurrentLocation.defaultProps = {
    zoom: 3,
    initialCenter: {
        lat: 44.5040502,
        lng: -98.2730204
    },
    centerAroundCurrentLocation: false,
    visible: true,
    selectedLocation: null,
    selectedAddress: {},
}
export default CurrentLocation;